


















import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";

@Component({ components: { VueApexCharts }})
export default class MetricsCard extends Vue {
  @Prop({ default: () => ({ options: {}, series: [] }) }) chartDefinition: any;
}



































import { Component, Vue, Watch } from "vue-property-decorator";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { addMonths, parse, format, parseISO } from "date-fns";

import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";
import MetricsCard from "../layouts/components/MetricsCard.vue";
import EntityCrud from "../layouts/components/EntityCrud.vue";
import userModel from "../models/user.model";

@Component({
  components: { EntityCrud, StatisticsCardLine, flatPickr, MetricsCard },
})
export default class Dashboard extends Vue {
  groupByOptions = [{ label: 'Dia', code: 'DAY' }, { label: 'Semana', code: 'WEEK' }, { label: 'Mês', code: 'MONTH' }];

  metricsOptions = {
    groupBy: "DAY",
    countType: "CUMULATIVE",
    startDate: addMonths(new Date(), -1),
    endDate: new Date(),
  };

  flatPickrConfig = {
    enableTime: false,
    dateFormat: "Z",
    altInput: true,
    altFormat: "d/m/Y",
  };

  charts: any[] = [
    { model: userModel, title: "Usuários", definition: { options: {} } },
  ];

  async mounted() {
    await this.changedMetricsOptions(this.metricsOptions);
  }

  @Watch("metricsOptions", { deep: true })
  async changedMetricsOptions(options: any) {
    this.$vs.loading();
    for (const chart of this.charts) {
      const type = "bar"; // options.countType === "CUMULATIVE" ? "line" : "bar";
      chart.definition = await this.getChart(await chart.model.getMetrics(options, chart.subEntity), type, chart.title);
    }
    this.$vs.loading.close();
  }

  getChart(metrics: any, type: string, title: string) {
    return {
      title,
      type,
      series: [{
        name: title,
        data: metrics.map((dataPoint: any) => (this.metricsOptions.countType === "CUMULATIVE" ? dataPoint.cumulativeCount : dataPoint.count)),
      }],
      options: {
        dataLabels: { enabled: false },
        stroke: { curve: "smooth" },
        xaxis: {
          title: { text: this.groupByOptions.find(option => option.code === this.metricsOptions.groupBy)?.label, style: { fontSize: "12px", fontWeight: "bold", color: "#555"} },
          categories: metrics.map((dataPoint: any) => this.formatPeriod(dataPoint.period)),
        },
      },
      firstValue: metrics[0]?.cumulativeCount,
      lastValue: metrics[metrics.length - 1]?.cumulativeCount,
    };
  }

  formatPeriod(period: string) {
    try {
      switch (this.metricsOptions.groupBy) {
        case "DAY":
          return format(parseISO(period), "dd/MM/yyyy");
        case "WEEK":
          return format(parse(period, "RRRR:II", new Date()), "dd/MM/yyyy");
        case "MONTH":
          return format(parse(period, "yyyy-MM", new Date()), "MMM yyyy");
      }
    } catch (error) {
      console.error(error);
      return "";
    }
  }
}
